.pretitle{
	font-size: 1.38rem;
	line-height: 1.63rem;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	color: #94B8C5;
}
.title{
	margin-top: 1.5rem;
	font-size: 5.63rem;
	line-height: 5.63rem;
	font-weight: bold;
	color: var(--atomic-blue);
	&_small{
		font-size: 3.00rem;
		line-height: 3.38rem;
	}
	&_medium{
		font-size: 4.50rem;
		line-height: 4.5rem;
	}
	&_big{
		font-size: 7.50rem;
		line-height: 7.50rem;
	}
}

.desc{
	font-size: 1.75rem;
	line-height: 2.25rem;
}
.explanation{
	margin: 0 auto;
	width: 100%;
	max-width: 56.25rem;
	font-weight: bold;
	color: var(--light-blue);
	font-size: 2.25rem;
	line-height: 3.00rem;
	text-align: center;
}
@media only screen and (max-width: 767px) {
	.desc{
		font-size: 1.00rem;
		line-height: 145%;
	}
	.pretitle{
		font-size: 0.75rem;
		line-height: 0.75rem;
	}
	.title{
		margin-top: 0.63rem;
		font-size: 2.50rem;
		line-height: 100%;
		&_small{
			font-size: 1.75rem;
			line-height: 1.75rem;
		}
		&_medium{
			font-size: 2.25rem;
			line-height: 100%;
		}
		&_big{
			font-size: 2.50rem;
			line-height: 100%;
		}
	}
	.explanation{
		font-size: 1.25rem;
		line-height: 1.63rem;
	}
	
}