.creator {
    display: flex;
    align-items: center;
    height: 2.875rem;
    background: var(--bg);
    &__item {
        display: flex;
        span {
            display: flex;
            font-size: 0.88rem;
            line-height: 130%;
            font-weight: 300;
            color: var(--atomic-blue);
        }
        &:nth-child(1) {
            padding-right: 0.88rem;
            position: relative;
            flex-direction: column;
            img {
                margin-top: 0.2rem;
                width: 7.69rem;
            }
            &::after {
                position: absolute;
                content: '';
                top: 50%;
                transform: translate3d(0, -50%, 0);
                right: -0.06rem;
                width: 0.07rem;
                height: 2.38rem;
                background: var(--atomic-blue);
            }
        }
        &:nth-child(2) {
            padding-left: 0.88rem;
            align-items: center;
            img {
                width: 2.88rem;
            }
            span {
                margin-left: 0.38rem;
                width: 12.06rem;
            }
        }
    }
}

.link {
    margin-left: 0.75rem;
    width: 1.875rem;
    height: 1.875rem;
}

.socs {
    display: flex;
    align-items: center;
}

.wrap {
    padding: 1.19rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}