.body{
	padding: 1.25rem;
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	z-index: 10;
}
.btn{
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 3.44rem;
	border-radius: 0.75rem;
	font-weight: bold;
	color: var(--green);
	font-size: 1.50rem;
	line-height: 1.75rem;
	background: var(--white);
}
.link{
	padding: 0.63rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-size: 1rem;
	line-height: 145%;
	&+.link{
		border-top: 0.07rem solid rgba(148, 184, 197, 0.3);
	}
}
.list{
	padding-top: 0.88rem;
	padding-bottom: 0.19rem;
	width: 100%;
	background: var(--white);
	border-radius: 0.75rem;
}
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: #60899B;
    opacity: 0.6;
}
.title{
	margin-bottom: 0.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.88rem;
	line-height: 150%;
	color: var(--light-blue);
}
.wrap {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    z-index: 100010;
    &.active {
        display: block;
    }
}