.body {
    position: absolute;
    width: 100%;
    top: calc(100% + 0.38rem);
    left: 0;
    
    padding:  0 0.25rem;
    border-radius: 0.75rem;
    background: var(--white);
    box-shadow: 0rem 0.25rem 0.94rem rgba(148, 184, 197, 0.50), 0rem 0.25rem 0.94rem rgba(148, 184, 197, 0.50);
    transform: translateY(-1rem);
    opacity: 0;
    pointer-events: none;
    transition: transform ease 0.3s, opacity ease 0.3s;
   
}
.scroll{

    width: 100%;
    max-height: 12.38rem;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0.19rem;
        height: 0.19rem;
    }
    &::-webkit-scrollbar-track {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        background-color: #fff;
        border-radius: 6.25rem;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 6.25rem;
        background: #BED8E1;
    }
}
.inner{
    
    padding: 1.0625rem 1.25rem;
}
.header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    padding: 0rem 1.5rem;
    border-radius: 0.75rem;
    background: var(--white, #FFF);
    border: 0.06rem solid var(--white);
    font-size: 1.375rem;
    font-variant-numeric: lining-nums proportional-nums;
    cursor: pointer;
    transition: all ease 0.3s;
    svg {
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        transition: transform ease 0.3s;
    }
}

.hidden {
    display: none;
}

.wrap {
    position: relative;
    width: 100%;
    &.active {
        .header {
            border-color: var(--light-blue, #94B8C5);
            background: transparent;
            svg {
                transform: translateY(-50%) rotate(180deg);
            }
        }
        .body {
            pointer-events: auto;
            transform: translateY(0rem);
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 767px) {
    .body {
        margin-top: 0.87rem;
        position: static;
        width: 100%;
        
    max-height: unset;
        padding: 0;
        left: 0;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        transform: none;
        opacity: 1;
        pointer-events: auto;
    }
    .inner{
        padding: 0;
        overflow: unset;
    }
    .header {
        width: 100%;
        height: auto;
        min-height: 1.625rem;
        padding: 0rem;
        border-radius: 0;
        background: transparent;
        border: none;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.625rem;
        svg {
            display: none;
        }
    }
    .hidden {
        margin-top: 0.5rem;
        padding: 0.31rem 0;
        display: block;
        &__btn {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            color: var(--atomic-blue, #11559B);

            svg {
                transition: transform ease 0.3s;
                margin-left: 1.44rem;
                width: 0.75rem;
            }
            &.active {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
        &__body {
            height: 0;
            overflow: hidden;
            transition: height ease 0.3s;
        }
        &__inner {
            padding: 0.9rem 0;
        }
    }
}