.wrap {
    display: none;
}

@media only screen and (max-width: 767px) {
    .accordeon {
        width: 100%;
        &+.accordeon {
            margin-top: 2rem;
        }
    }
    .btn {
        position: fixed;
        bottom: 1.25rem;
        left: 50%;
        transform: translate(-50%);
        width: 20.9375rem;
        height: 3.4375rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.75rem;
        font-size: 1.5rem;
        color: var(--white);
        font-weight: 700;
        line-height: 1.75rem;
        background: var(--green, #61BB1A);
		z-index: 10;
    }
    .body {
        height: calc(100% - 4.6875rem);
        overflow: auto;
    }
    .header {
        padding: 0 1.25rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 4.6875rem;
        border-radius: 0rem 0rem 0.5rem 0.5rem;
        background: var(--white);
        &__link {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 4.625rem;
            height: 1.3125rem;
            color: var(--atomic-blue, #11559B);
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 100%;
        }
    }
    .item {
        width: 100%;
        &+.item {
            margin-top: 0.75rem;
        }
    }
    .inner {
        padding: 0.63rem 0 6rem;
    }
    .list {
        padding: 0.56rem 1.25rem 2rem;
        border-radius: 0.75rem;
        background: var(--white);
        box-shadow: 0rem 0rem 1.25rem rgba(33, 40, 92, 0.10);
    }
    .title {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.75rem;
    }
    .wrap {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        background: #F3FAFD;
        z-index: 100010;
        &.active{
            display: block;
        }
    }
}