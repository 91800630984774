:root {
   --white: #ffffff;
   --bg: #F3FAFD;
   --black: #26262D;
   --yellow: #F0A600;
   --orange: #EE6C23;
   --green: #61BB1A;
   --emerald: #259789;
   --red: #EA3858;
   --atomic-blue: #11559B;
   --light-blue: #94B8C5;
}

html {
    font-size: 1.111vw;
    width: 100%;
    background: #F3FAFD;
    -webkit-font-smoothing: antialiased;
}

body {
    position: relative;
    width: 100%;
    font-family: 'Alegreya Sans';
    font-variant-numeric: lining-nums;
    font-size: 1.38rem;
    line-height: 1.88rem;
    color: var(--black);
    transition: opacity ease 0.5s;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    flex-shrink: 0;
}

sub, sup {
    line-height: 0;
    font-size: 0.7em;
}

#root {
    position: relative;
    width: 100%;
    z-index: 10;
}

span {
    user-select: none;
}

.wrap {
    padding-top: 2.81rem;
    position: relative;
    width: 100%;
}

.container {
    position: relative;
    width: 100%;
    padding: 0 7.50rem;
}
.grid{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
}
a{
    text-decoration: none;
    transform: translateZ(0);
}
img, svg, picture {
    height: auto;
    width: 100%;
    flex-shrink: 0;
}
picture{
    display: flex;
    align-items: flex-start;
}
span, p, input, button, a, ul {
    font: inherit;
    color: inherit;
}
p+p{
    margin-top: 1.25rem;
}
[data-lazy]{
    opacity: 0;
    transition: opacity ease 0.2s;
    &.loaded{
        opacity: 1;
    }
}
@media only screen and (max-width: 767px) {
    html {
        font-size: 4.267vw;
    }
    * {
        cursor: unset !important;
    }
    .container {
        padding: 0 1.25rem;
    }
    .wrap{
        padding-top: 2rem;
        overflow: hidden;
    }
    p+p{
        margin-top: 1.5rem;
    }

}