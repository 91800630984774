.icon {
	position: relative;
	align-self: flex-start;
	display: flex;

	align-items: center;
	justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.1875rem;
    background: #E8F1F4;
	cursor: pointer;
	transform: translateZ(0);
	svg{
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 0.69rem;
	}
	&_radio{
		border-radius: 50%;
		svg{
			width: 0.75rem;
		}
	}
}
.input{
	display: none;
	&:checked~.icon{
		background: #94B8C5;
		svg{
			display: flex;
		}
	}
}
.name{
	display: flex;
	align-items: center;
	margin-left: 1.06rem;
	width: 100%;
	flex-shrink: unset;
	font-size: 1rem;
	line-height: 1.375rem;
}
.wrap {
    display: flex;
	align-items: center;
	min-height: 1.375rem;
	cursor: pointer;
}