.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.footer{
    margin-top: auto;
    display: flex;
}
.main {
    padding-top: 10.25rem;
    padding-bottom: 3.5rem;
}

.subtitle {
    color: var(--atomic-blue, #11559B);
    font-size: 1.375rem;
    line-height: 1.875rem;
}

.wrap {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 2.75rem);
    opacity: 1;
    transition: opacity ease 0.3s;
    &.transition{
        opacity: 0;
        pointer-events: none;
    }
}
@media only screen and (max-width: 767px) {
    .footer{
        display: none;
    }
}