.wrap {
    padding: 0.31rem 1.25rem 0.25rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 100000;
    pointer-events: none;
    // &_invert {
    //     .logo svg path {
    //         fill: var(--white);
    //     }
    //     .burger svg path {
    //         stroke: var(--white);
    //     }
    // }
    background: var(--white);
}

.inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &>* {
        pointer-events: auto;
    }
}

.logo {
    width: 10.94rem;
    display: flex;
    align-items: flex-start;
    svg path {
        transition: fill ease 0.2s;
    }
}

.burger {
    width: 5.00rem;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    svg path {
        transition: fill ease 0.2s;
    }
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100009;
    background: var(--white);
    opacity: 0;
    pointer-events: none;
    transition: opacity ease 0.5s;
    &.active {
        opacity: 0.6;
        pointer-events: auto;
    }
}

.menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 30.94rem;
    background: var(--bg);
    z-index: 100010;
    transform: translate(calc(101% + 4.7rem));
    pointer-events: none;
    box-shadow: 0rem 0rem 4.69rem rgba(118, 192, 223, 0.20);
    transition: transform ease 0.5s;
    &__close {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
        display: flex;
        width: 2.25rem;
        height: 2.25rem;
        cursor: pointer;
        z-index: 100;
    }
    &__inner {
        height: calc(100% - 6.13rem);
        width: 100%;
        overflow: auto;
        -webkit-overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    &__list {
        padding-top: 3.75rem;
        padding-left: 2.19rem;
        width: 24.06rem;
        display: flex;
        flex-direction: column;
    }
    &.active {
        pointer-events: auto;
        transform: translate(0);
    }
}

.creator {
    padding: 1.63rem 0;
    position: absolute;
    bottom: 0;
    left: 2.19rem;
    display: flex;
    align-items: center;
    width: 100%;
    height: 6.13rem;
    background: var(--bg);
    &__item {
        display: flex;
        span {
            display: flex;
            font-size: 0.88rem;
            line-height: 130%;
            font-weight: 300;
            color: var(--atomic-blue);
        }
        &:nth-child(1) {
            padding-right: 0.88rem;
            position: relative;
            flex-direction: column;
            img{
                margin-top: 0.2rem;
                width: 7.69rem;
            }
            &::after{
                position: absolute;
                content: '';
                top: 50%;
                transform: translate3d(0, -50%, 0);
                right:  -0.06rem;
                width: 0.07rem;
                height: 2.38rem;
                background: var(--atomic-blue);
            }
        }
        &:nth-child(2) {
            padding-left: 0.88rem;
            align-items: center;
            img{
                width: 2.88rem;
            }
            span{
                margin-left: 0.38rem;
                width: 12.06rem;
            }
        }
    }
}

.collapse {
    &__header {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &__pretitle {
        font-size: 0.88rem;
        line-height: 1.06rem;
    }
    &__title {
        margin-top: 0.25rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        color: var(--atomic-blue);
        font-weight: bold;
        line-height: 1.50rem;
        cursor: pointer;
        &::before {
            position: absolute;
            content: '';
            height: 100%;
            left: -2.19rem;
            bottom: 0;
            width: 0.56rem;
            opacity: 0;
            background: var(--yellow);
        }
        svg {
            width: 1.19rem;
        }
    }
    &__body {
        margin-left: -2.19rem;
        padding-left: 2.19rem;
        height: 0;
        overflow: hidden;
        transition: height ease 0.3s;
    }
    &__inner {
        padding: 1.63rem 0;
        display: flex;
        flex-direction: column;
    }
    &+.collapse {
        margin-top: 1.25rem;
    }
    &:not(.active) .collapse__title:hover {
        &::before {
            opacity: 0.2;
        }
    }
    &.active:not(.open) {
        .collapse__title {
            color: var(--yellow);
            &::before {
                opacity: 1;
            }
        }
    }
    &.open {
        .collapse__title svg {
            transform: rotate(180deg);
            path {
                stroke: var(--yellow)
            }
        }
    }
}

.link {
    position: relative;
    color: var(--atomic-blue);
    &::before {
        position: absolute;
        content: '';
        height: 100%;
        left: -2.19rem;
        bottom: 0;
        width: 0.56rem;
        opacity: 0;
        background: var(--yellow);
    }
    &:hover:not(.active) {
        &::before {
            opacity: 0.2;
        }
    }
    &+.link {
        margin-top: 1.25rem;
    }
    &.active {
        color: var(--yellow);
        &::before {
            opacity: 1;
        }
    }
}
.back {
    display: none;
}
@media only screen and (max-width: 767px) {
    .back{
        display: flex;
        align-items: center;
        font-size: 0.88rem;
        line-height: 100%;
        font-weight: bold;
        color: var(--green);
        svg{
            margin-bottom: 0.1rem;
            margin-right: 0.36rem;
            height: 0.81rem;
            width: 0.81rem;
        }
    }
    .burger {
        display: flex;
        align-items: flex-start;
        width: 2.81rem;
        height: 1.75rem;
        cursor: unset;
    }
    .logo {
        display: flex;
        align-items: flex-start;
        width: 7.19rem;
    }
    .collapse {
        &__pretitle {
            font-size: 0.88rem;
            line-height: 1.06rem;
        }
    }
    .menu {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        box-shadow: none;
        font-size: 1.25rem;
        line-height: 145%;
        &__close {
            width: 1.5rem;
            height: 1.5rem;
            cursor: unset;
        }
        &__inner {
            height: calc(100% - 6.13rem);
            width: 100%;
        }
        &__list {
            width: 100%;
            padding-right: 1rem;
        }
    }
    .creator {
        padding: 1.63rem 0;
        height: 6.13rem;
        &__item {
            span {
                font-size: 0.66rem;
            }
            &:nth-child(1) {
                padding-right: 0.75rem;
                img{
                    margin-top: 0.2rem;
                    width: 6.59rem;
                }
                &::after{
                    width: 0.06rem;
                    height: 2rem;
                }
            }
            &:nth-child(2) {
                padding-left: 0.75rem;
                img{
                    width: 2.28rem;
                }
                span{
                    margin-left: 0.38rem;
                    width: 11rem;
                }
            }
        }
    }
    
    .wrap {
        padding: 0.19rem 0.56rem;
        background: var(--white);
        transition: background ease 0.2s;
        &_invert {
            background: transparent;
        }
    }
}